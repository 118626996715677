import React, { useEffect, useState } from "react";
import { Link } from "@reach/router";

const Posts = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {

    const getPosts = async () => {
      const resp = await fetch(
        "https://workers.arisa77.workers.dev/api/posts"
      );
      const postsResp = await resp.json();
      console.log(postsResp);
      setPosts(JSON.parse(postsResp) || []);
    };

    getPosts();
    
    //document.querySelector("#create").addEventListener('click', createPost);

  }, []);

  var createPost = function(){
      var username = document.querySelector("input[name=username]").value;
      var title = document.querySelector("input[name=title]").value;
      var content = document.querySelector("textarea").value;
      if (title.length || username.length || content.length) {
        const post = {
          "id": posts.length + 1, 
          "title": title || "unknown title",
          "username":username || "unknown user",
          "content":content || "empty content" };
        fetch("https://workers.arisa77.workers.dev/api/posts",
         { method: 'POST', body: JSON.stringify(post) });
        let new_posts = [...posts]
        new_posts.push(post)
        setPosts(new_posts)
      }
  
      
  };
  return (

          <div>
            <div class="admin-quick-add">
              <h1>Create Post</h1>
              <input type="text" name="username" placeholder="User Name"></input>
              <br />
              <input type="text" name="title" placeholder="Title"></input>
              <br />
              <textarea name="content" placeholder="Content"></textarea>
              <br />
              <button id="create" type="submit" onClick={() => createPost()}>Create Post</button>
            </div>
            <h1>All Posts</h1>
            {posts.map((post) => (
              <div key={post.id}>
                <h2>
                  <Link to={`/posts/${post.id}`}>{post.title}</Link>
                </h2>
              </div>
            ))}
          </div>

  );
};



export default Posts;

